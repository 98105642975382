<template>
    <div>
      <!-- title -->
      <div class="container">
        <el-image  style="width:1.8rem; height:1.5rem" :src="imgurl"></el-image>
        <!-- <span  style="width: 1rem; height: 1rem;inline-size: 1rem;font-size: 1rem;margin-right: 1rem;margin-left:0.5rem;">abc</span> -->
      <el-menu class="right" :default-active="activeIndex" mode="horizontal" >   
        <el-menu-item index="1">About Toastmasters</el-menu-item>
        <el-menu-item index="2">Upcoming Events</el-menu-item>
        <!-- <el-menu-item index="3">处理中心</el-menu-item>       -->
      </el-menu>
    </div>
    <div>
      <about></about>
    </div>
      <!-- title -->
    </div>
</template>



<script>
 import about from './about.vue';
  export default {
    components: { about },
    data() {
      return {
        imgurl:require('../assets/color.png'),
        activeIndex: '1',
      };
    },
    methods: {
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
    }
  }
</script>

<style lang="less"  scoped>
.container {
  display: flex;
  background-color: rgb(167, 56, 56);
}
.left {
  flex: 3;
}
.right {
  flex: 3;
  background-color: rgb(167, 56, 56);
}
/deep/.el-menu.el-menu--horizontal{
  border-bottom:solid 0px #e6e6e6
}
/deep/.el-menu--horizontal>.el-menu-item.is-active
{
  border-bottom:solid 0px #e6e6e6
}

</style>