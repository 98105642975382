import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
// 引入一下用到的组件 
import Luna from '../components/Luna.vue'
import Home from '../views/home.vue'
// 第二步创建router实例对象并暴露
export default new Router({
    mode:'history',
    routes: [
        {
            path: '/',
            name:'Home',
            component: Home
        },
        {
            path: '/Luna',
            name:'Luna',
            component: Luna
        },       
    ]
})
