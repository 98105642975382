<template>
    <div>
      <!-- title -->
      <div class="container">
        <el-image   :src="imgurl"></el-image><br>
        <el-image   :src="imgurl1"></el-image><br>
        <el-image   :src="imgurl2"></el-image><br>
        <el-image   :src="imgurl3"></el-image><br>
        <el-image   :src="imgurl4"></el-image><br>
        <el-image   :src="imgurl5"></el-image><br>
        <el-image   :src="imgurl6"></el-image><br>
        <el-image   :src="imgurl7"></el-image><br>
        <el-image   :src="imgurl8"></el-image><br>
        <el-image   :src="imgurl9"></el-image><br>
        <el-image   :src="imgurl10"></el-image><br>

    </div>
      <!-- title -->
    </div>
</template>



<script>
  export default {
    data() {
      return {
        imgurl:require('../assets/aboutimage/daquzhang.png'),
        imgurl1:require('../assets/aboutimage/1.png'),
        imgurl2:require('../assets/aboutimage/2.png'),
        imgurl3:require('../assets/aboutimage/3.png'),
        imgurl4:require('../assets/aboutimage/4.png'),
        imgurl5:require('../assets/aboutimage/5.png'),
        imgurl6:require('../assets/aboutimage/6.png'),
        imgurl7:require('../assets/aboutimage/7.png'),
        imgurl8:require('../assets/aboutimage/8.png'),
        imgurl9:require('../assets/aboutimage/9.png'),
        imgurl10:require('../assets/aboutimage/10.png'),
      };
    },
    methods: {
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
    }
  }
</script>

<style lang="less"  scoped>

</style>